<script lang="ts" setup>

</script>

<template>
  <div>
    <TheHeader />

    <slot />

    <TheFooter />
  </div>
</template>
